<div mat-dialog-title>
  <h1>TypeDB Pricing</h1>
  <td-dialog-close-button />
</div>
<div mat-dialog-content>
  <div>
    <p>Clusters are billed hourly, and invoiced monthly (pay-as-you-go). Monthly estimates are based on 100% cluster uptime over a 30-day period.</p>
    <p>These prices are per node in a given cluster. The table below shows sample configurations to illustrate scale, but machine (compute and storage) can be further fine-tuned in TypeDB Cloud.</p>
  </div>
  <hr/>
  <div>
    <div class="cards-row">
      @for (providerId of providerIds; track providerId) {
        <button type="button" class="provider-card" [ngClass]="providerCardClasses(providerId)" (click)="updateProviderId(providerId)">
          <div class="card-content">
            <img [src]="providerImageUrl(providerId)" [alt]="providerId | provider" />
          </div>
        </button>
      }
    </div>
  </div>
  <div class="td-table-area">
    @if (loading) {
      <tp-spinner/>
    } @else {
      <table>
        <thead>
        <tr>
          <th>Machine tier ({{ providerId | provider }})</th>
          <th>Storage</th>
          <th>Compute</th>
          <th>Hourly rate</th>
          <th>Monthly est.</th>
        </tr>
        </thead>
        <tbody>
          @switch (providerId) {
            @case ("gcp") {
              <tr>
                <td>c2d-highcpu-2</td>
                <td>10 GB</td>
                <td>2 vCPU, 4 GB RAM</td>
                <td><strong>$0.16/hr</strong></td>
                <td>$115/mo</td>
              </tr>
              <tr>
                <td>c2d-highcpu-4</td>
                <td>50 GB</td>
                <td>4 vCPU, 8 GB RAM</td>
                <td><strong>$0.32/hr</strong></td>
                <td>$230/mo</td>
              </tr>
              <tr>
                <td>c2d-highcpu-8</td>
                <td>400 GB</td>
                <td>8 vCPU, 16 GB RAM</td>
                <td><strong>$0.72/hr</strong></td>
                <td>$518/mo</td>
              </tr>
              <tr>
                <td>c2d-highcpu-16</td>
                <td>1 TB</td>
                <td>16 vCPU, 32 GB RAM</td>
                <td><strong>$1.48/hr</strong></td>
                <td>$1066/mo</td>
              </tr>
            }
            @case ("aws") {
              <tr>
                <td>c8g.large</td>
                <td>10 GB</td>
                <td>2 vCPU, 4 GB RAM</td>
                <td><strong>$0.17/hr</strong></td>
                <td>$122/mo</td>
              </tr>
              <tr>
                <td>c8g.xlarge</td>
                <td>50 GB</td>
                <td>4 vCPU, 8 GB RAM</td>
                <td><strong>$0.34/hr</strong></td>
                <td>$245/mo</td>
              </tr>
              <tr>
                <td>c8g.2xlarge</td>
                <td>400 GB</td>
                <td>8 vCPU, 16 GB RAM</td>
                <td><strong>$0.76/hr</strong></td>
                <td>$547/mo</td>
              </tr>
              <tr>
                <td>c8g.4xlarge</td>
                <td>1 TB</td>
                <td>16 vCPU, 32 GB RAM</td>
                <td><strong>$1.56/hr</strong></td>
                <td>$1123/mo</td>
              </tr>
            }
            @case ("azure") {
              <tr>
                <td>Standard_F2s_v4</td>
                <td>10 GB</td>
                <td>2 vCPU, 4 GB RAM</td>
                <td><strong>$0.18/hr</strong></td>
                <td>$130/mo</td>
              </tr>
              <tr>
                <td>Standard_F4s_v4</td>
                <td>50 GB</td>
                <td>4 vCPU, 8 GB RAM</td>
                <td><strong>$0.35/hr</strong></td>
                <td>$252/mo</td>
              </tr>
              <tr>
                <td>Standard_F8s_v4</td>
                <td>400 GB</td>
                <td>8 vCPU, 16 GB RAM</td>
                <td><strong>$0.77/hr</strong></td>
                <td>$554/mo</td>
              </tr>
              <tr>
                <td>Standard_F16s_v4</td>
                <td>1 TB</td>
                <td>16 vCPU, 32 GB RAM</td>
                <td><strong>$1.58/hr</strong></td>
                <td>$1138/mo</td>
              </tr>
            }
          }
        </tbody>
      </table>
    }
  </div>
  <aside>
    Listed prices are for the US East region and include both storage and compute. Storage is priced at
    @switch (providerId) {
      @case ("gcp") {$0.00028/hr}
      @case ("aws") {$0.00028/hr}
      @case ("azure") {$0.00022/hr}
    }
    per GB per cluster node, and is charged whether a cluster is running or suspended.
  </aside>
</div>
